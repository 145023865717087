<template>
    <ContentWrapper>
        <breadcrumb/>

        <div class="container">
            <tickets :limit="9999"></tickets>
        </div>
    </ContentWrapper>
</template>
<script>
    import Tickets from "../components/Tickets";
    export default {
        components: {Tickets},
    }
</script>